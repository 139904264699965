@use "@angular/material" as mat;
@use "./colors";

/* ======== Angular material custom themes ======== */
$app-primary: mat.define-palette(colors.$custom-brand-primary);
$app-accent: mat.define-palette(colors.$custom-brand-accent);
$app-warn: mat.define-palette(colors.$custom-brand-warn);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include mat.all-component-themes($my-theme);
@include mat.core();
