@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "ngx-toastr/toastr";
* {
  --neutral-100: #fafafa;
  --neutral-200: #eeeeee;
  --neutral-300: #e0e0e0;
  --neutral-400: #c9c9c9;
  --neutral-500: #9e9e9e;
  --neutral-600: #7f7f7f;
  --neutral-700: #666666;
  --neutral-800: #474747;
  --neutral-900: #212121;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  height: 100%;
}

mat-form-field {
  width: 100%;
  margin-bottom: 1rem;
  input.mdc-text-field__input {
    padding: 0.25rem 0 0 0;
  }
}

md-icon {
  display: contents;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #fff;
}

.mdc-button__label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
}

.mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
